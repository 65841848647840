html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  height: 3vmin;
  pointer-events: none;
}

.App-main {
  flex: 1;
}

.App-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;

  padding: 5px 5px 10vmin 5px;

  background-color: #282c34;
  color: white;
  font-size: calc(10px + 1vmin);
}

.App-footer div {
  margin-top: 3vmin;
}

.App-footer a {
  color: white;
}

.Phasekeeper-logo {
  pointer-events: none;
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 64px;
  width: 64px;
}

.RoundInProgress-phase-indicator {
  pointer-events: none;
  margin-right: 5px;
  color: red;
  border: 3px solid red;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .Phasekeeper-logo {
    animation: App-logo-spin infinite 5s alternate-reverse;
  }

  .Phasekeeper-logo.no-animate {
    animation: none;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Game-view {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.Game-setup-instruction {
  font-style: italic;
  margin-top: 3vmin;
}

.Roster-container {
  margin: 20px 0;
}

.Roster-heading {
  text-decoration: underline;
}

.Roster-players {
  padding: 5px 0;
}

.Roster-player {
  margin: 5px 0;
}
.Roster-remove {
  color: red;
  font-style: italic;
  margin-left: 5px;
}

.PhaseCard-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  border: 1px solid black;
  justify-content: flex-start;
  align-items: center;
}

.PhaseCard-players {
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
}

.PhaseCard-goal {
  align-self: center;
  margin-left: 10px;
}

.PhaseCard-player {
  margin-right: 5px;
}

.ScoreCard-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  border: 1px solid black;
  justify-content: flex-start;
}

.ScoreCard-player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ScoreCard-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.App-header a {
  color: white;
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media all and (display-mode: standalone) {
  .App-footer-install-hint {
    display: none;
  }
}